@import '~antd/dist/antd.css';

.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.logo {
  height: 32px;
  /*margin: 16px;*/
  margin-left: 0px;
  /*background: rgba(255, 255, 255, 0.3);*/
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.editable-cell-value-wrap{
  padding: 20px 12px;
}
.ant-table-tbody > tr > td{
  padding: 0 10px !important;
}

.login-form {
  /*display: flex;*/
  /*justify-content: space-between;*/
  position:absolute;
  width: 450px;
  left:50%;
  top: 20%;
  transform:translate(-50%,0);
}

.login-form1 {
  /*display: flex;*/
  /*justify-content: space-between;*/
  position:absolute;
  width: 400px;
  left:50%;
  /*top: 20%;*/
  transform:translate(-50%,0);
}

.login-form-forgot {
  float: right;

}
.login-form-button {
  width: 100%;

}

.modifyPwd {
    width: 32%;

}

.addCustomer {
  width: 300px;

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/*p,ul,li {margin:20px;padding:0;}*/
/*#all {width:600px;display:flex;}*/




/*.m-sys-wrap{position: absolute;top: 50px;left: 0;right: 0;bottom: 0;}*/
/*.m-sys-inner{position: absolute; width: 1280px;left: 50%;transform: translateX(-50%);}*/
/*.m-sys-header{margin: 30px 0 0 0;}*/
/*.m-sys-set-icon{position: absolute;top: 29px; color: #ffffff;}*/
/*.m-sys-set-text{margin: 0 0 0 26px; line-height: 20px;font-size: 14px;color: #ffffff;vertical-align: top;}*/
/*.m-sys-tab-wrap{display: inline-block; margin: 0 0 0 30px; padding: 0;}*/
/*.m-sys-tab{display: inline-block; position: relative;margin: 0 0 0 10px;  padding: 0 6px;line-height: 20px; color: #888888;list-style-type: none;background-color: #444444;border-radius: 2px;cursor: pointer;}*/
/*.m-sys-tab:hover{opacity: 0.8}*/
/*.m-sys-tab.active{color: #ffffff;background-color: #40677f;}*/
/*.m-sys-tab-icon{position: absolute;top: 1px; font-size: 14px;}*/
/*.m-sys-tab-text{display: inline-block; margin: 0 0 0 25px; font-size: 12px;}*/
/*.m-sys-view{display: none;color: #ffffff;font-size: 32px;}*/
/*.m-sys-view.active{display: block;}*/


/*.m-button{display: inline-block;}*/
.m-button{display: inline-block;}
.m-view{display: none;}
.m-view.active{display: block;}

.m-view1{display: none;margin-top: 20px}
.m-view1.active{display: block;margin-top: 20px}

.mycolumns {
  display: block;
  align-items: center;
  width: 116px;
}

.mycolumns1 {
  display: block;
  align-items: center;
  width: 250px;
}

.mycolumns2 {
  display: block;
  align-items: center;
  width: 500px;
}

.mycolumns3 {
  display: block;
  align-items: center;
  width: 800px;
}

.mycolumns4 {
  display: block;
  align-items: center;
  /*width: global.constants.fieldcolumnwidth;*/
  width: 1800px;
}

.mycircle {
  color:#108ee9;
  font-size:18px;
}

/*.gutter-example .ant-row > div {*/
  /*background: transparent;*/
  /*border: 0;*/
/*}*/
/*.gutter-box {*/
  /*background: #00a0e9;*/
  /*padding: 5px 0;*/
/*}*/


.gutter-example .ant-row > div {
  background: transparent;
  border: 0;
}
.gutter-box {
  background: transparent;
  padding: 5px 0;
}




.display-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap:  wrap
}

.display-flex-printtitle {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap:  nowrap;
}

.display-flex-printfoot {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap:  nowrap;
}

.display-mid {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap:  nowrap
}

.display-flex1 {
  display: flex;
  flex-direction:column;
  height:100%;
  justify-content: space-between;

  /*align-items: flex-end;*/
}

.mywork-flex {
  display: flex;
  flex-direction:row;
  height:100%;
  justify-content: flex-end;
}

#flexContainer {
  display: flex;
  /*flex-direction: row;*/
  justify-content: center;
  /*align-items: center;*/
}

#flexContainer1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
#side {width:200px;float:left;}

#all {display:flex;justify-content: center;flex-wrap: nowrap}
#main {width:400px;float:left;}

.banner {
  color:dodgerblue;
  font-size:40px;
  /*font-style:oblique;*/
  font-weight:bold;
  font-family:STKaiti;
  /*text-align: center;*/

  /*background-color: #F5FFFA*/
}

.biginfobox{
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.infobox{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 150px;
  height: 100px;
  margin-top:20px;
  margin-left: 20px;
  margin-bottom: 20px;
  color: white;
  /*background: -webkit-gradient(linear,left top,left bottom,from(#ff00ca),to(#cd9400));*/
  background: #5599FF;
}

/*.banner1 {*/
  /*color:dodgerblue;*/
  /*font-size:40px;*/
  /*!*font-style:oblique;*!*/
  /*font-weight:bold;*/
  /*font-family:STKaiti;*/
  /*text-align: center;*/
  /*float:left;*/
  /*position:relative;*/
  /*left:20%;*/
  /*!*background-color: #F5FFFA*!*/
/*}*/



.box{
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  height: 80px;
}

.box1{
  display: flex;
  justify-content: flex-start;
  align-items:center;
  background: white;
  margin-top: 10px;
}

.box2{
    display: flex;
    justify-content: center;
    align-items:flex-start;
    /*background: white;*/
    margin-top: 10px;

}

.box3{
  display: flex;
  justify-content: center;

  align-items:center;
  background: white;
  /*height: 80px;*/
}

.box4{
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
  height: 80px;
}

.box5{
  display: flex;
  flex-direction: row;
  justify-content: center;
  /*width: 200px;*/
  /*margin-bottom: 5px;*/
  /*align-items: center;*/
  /*background: white;*/
  /*height: 80px;*/
}

.box6{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  /*height: 80px;*/
}

.box7{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: white;
  height: 80px;
}

.box8{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /*text-align: center;*/
  /*width: 680px;*/
  align-items: flex-start;
  /*background: white;*/
  /*height: 80px;*/
}


.box9{
  display: flex;
  flex-direction: row;
  justify-content: center;
  /*text-align: center;*/
  /*width: 900px;*/
  align-items: center;
  /*background: white;*/
  /*height: 80px;*/
}

.box10{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /*text-align: center;*/
  /*width: 680px;*/
  align-items: center;
  /*background: white;*/
  /*height: 80px;*/
}

.box11{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  /*text-align: center;*/
  /*width: 680px;*/
  align-items: flex-start;
  /*background: white;*/
  /*height: 80px;*/
}

.box12{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /*text-align: center;*/
  /*width: 900px;*/
  align-items: center;
  /*background: white;*/
  /*height: 80px;*/
}

.box18{
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  /*width: 300px;*/
  /*margin-bottom: 5px;*/
  /*align-items: center;*/
  /*background: white;*/
  /*height: 80px;*/
}

.box_needproduct1{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: white;
  /*height: 80px;*/
}
.customer_modal_wrapper .ant-modal-footer{
  display: none;
}
.customer_form_wrapper .ant-form-item-label{
  width: 100px;
  text-align: right;
}
.customer_form_wrapper .ant-col-24 .ant-form-item-control-wrapper{
  width: 100%;
}
.customer_form_wrapper .ant-col-24 .customer_form_wrapper_button{
  border-top: 1px solid #e8e8e8;
  padding-top: 20px;
  margin-bottom: 0;
}
.customer_form_wrapper .ant-col-24 .customer_form_wrapper_button .ant-form-item-children{
  display: flex;
  justify-content: flex-end;
}
.customer_form_wrapper .ant-col-24 .customer_form_wrapper_button .ant-form-item-children > button{
  width: 64px;
}
.customer_form_wrapper .ant-col-24 .customer_form_wrapper_button .ant-form-item-children > button:last-child{
  margin-left: 8px;
}
.box_needproduct2{
  display: flex;
  /*justify-content: left;*/
  /*width: 200px;*/
  /*margin-bottom: 5px;*/
  /*align-items: center;*/
  /*background: white;*/
  /*height: 80px;*/
}
.box_needproduct2 .ant-form-item{
  display: flex;
}
.box_needproduct2 .full-line .ant-form-item-control-wrapper{
  flex-grow: 1;
}
.box_needproduct2 .button-wrapper{
  display: flex;
  justify-content: space-between;
}

.box_needproduct3{
  display: flex;
  flex-direction: column;
  /*justify-content: center;*/
  justify-content: flex-start;
  /*width: 200px;*/
  /*margin-bottom: 5px;*/
  /*align-items: center;*/
  /*background: white;*/
  /*height: 80px;*/
}

.box_needproduct4{
  display: flex;
  flex-direction: row;
  /*justify-content: center;*/
  justify-content: center;
}

.myrow{
  display: flex;
  flex-direction: row;
  justify-content: center;
  /*width: 900px;*/
  /*align-items: center;*/
  /*background: white;*/
  /*height: 80px;*/
}

.flowright{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 350px;
  /*align-items: center;*/
  /*background: white;*/
  /*height: 80px;*/
}

.flowrightcolor{
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border-bottom:1px solid lightslategrey;
  margin-bottom: 10px;
  /*border:1px;*/
  /*background-color: grey;*/
  /*width: 900px;*/
  /*align-items: center;*/
  /*background: white;*/
  /*height: 80px;*/
}

.addsupplier{
  display: flex;
  flex-direction: row;
  justify-content: center;
  /*width: 900px;*/
  /*align-items: center;*/
  /*background: white;*/
  /*height: 80px;*/
}

/*.imgwidth{*/
/*  width: 55px;*/
/*}*/
.imgwidth{
  width: 180px;
}


/*.banner1{*/
/*  height: 65px;*/
/*}*/

.banner1{
  height: 35px;
  margin-left: 5px;
}

.banner3{
  height: 30px;
  margin-left: 0px;
}

.banner3{
  height: 30px;
  margin-left: 0px;
}

.banner_qgzs{
  height: 60px;
  margin-left: 10px;
}

.banner2{
  height: 55px;
  margin-right: 5px;
}

.bannerflex{
  display: flex;
  /*justify-content: space-between;*/
  justify-content: center;
  align-items: center;
  background: white;
  height: 70px;
}

.bannerflex1{
  display: flex;
  justify-content: space-between;
  /*justify-content: center;*/
  align-items: center;
  /*background: white;*/
  background: #001528;
  height: 70px;
  margin-left: 0;
}
.title{
  margin:10px 0px;
  padding:0px;
  /*background-color:#E7EAEB;*/
  /*color:#00F;*/
  color: dodgerblue;
  font-family:"微软雅黑","黑体","宋体";
  font-size:36px;
  height:40px;
}

.printtitle{
  margin:0px;
  padding:0px;
  /*background-color:#E7EAEB;*/
  /*color:#00F;*/
  text-align:center;
  color: black;
  font-family:"微软雅黑","黑体","宋体";
  font-size:30px;
  height:35px;
}

.printfoot{
  margin:0px;
  padding:0px;
  /*background-color:#E7EAEB;*/
  /*color:#00F;*/
  text-align:center;
  color: black;
  font-family:"微软雅黑","黑体","宋体";
  font-size:18px;
  height:20px;
}

.gradient{
  background: #000000;
  background: -moz-linear-gradient(top,  #000000 0%, #ffffff 100%);
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#000000), color-stop(100%,#ffffff));
  background: -webkit-linear-gradient(top,  #000000 0%,#ffffff 100%);
  background: -o-linear-gradient(top,  #000000 0%,#ffffff 100%);
  background: -ms-linear-gradient(top,  #000000 0%,#ffffff 100%);
  background: linear-gradient(to bottom,  #000000 0%,#ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#ffffff',GradientType=0 );
}
:root .gradient{filter:none;}

.top{
  margin-top: 10px;
}

.top1{
  margin-top: 23px;
}

img{
  /*width: 90px;*/
  /*height: 60px;*/
}

.wenzi {
  color:dodgerblue;
  font-size:40px;
  /*font-weight:bold;*/
  font-family:STKaiti;
}

.wenzi1 {
  color:dodgerblue;
  /*font-size:40px;*/
  font-weight:bold;
  font-family:STKaiti;
}

.wenzi3 {
  /*color:dodgerblue;*/
  /*font-size:40px;*/
  font-weight:bold;
  /*font-family:STKaiti;*/
}

.wenzi4 {
  color:dodgerblue;
  font-size:17px;
  font-weight:bold;
  /*font-family:STKaiti;*/
}

.wenzi5 {
  /*color:dodgerblue;*/
  font-size:17px;
  /*margin-left: 5px;*/
  /*font-weight:bold;*/
  /*font-family:STKaiti;*/
}

.authtitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  /*color:dodgerblue;*/
  /*font-size:20px;*/
  /*font-weight:bold;*/
  /*font-family:STKaiti;*/
}

.child-one {
  /*flex-grow: 0;*/
  /*flex-shrink: 0;*/
  height: 100px;
  width: 100px;

}

.denglu {
  font-size: 20px;
  color:dodgerblue;
  font-family:STKaiti;
  font-weight:bold;

}

.divleft {
  float: left;
}

.footer {
    width: 100%;
    height:100px; /* footer的高度一定要是固定值*/
    position:absolute;
    bottom:0px;
    left:0px;

    /*background: #333;*/
}

.comment {
   background: #c0c0c0;
   margin-top: 20px;
}

.inputwidth {
  width: 100%;
  margin-right: 10px;
}

.inputwidth1 {
  width: 800px;
  /*margin-left: 30px;*/
}


.inputwidth2 {
  width: 270px;
  /*margin-left: 30px;*/
}

.inputwidth3 {
  width: 835px;
  /*margin-left: 30px;*/
}

.highlight-overlay {
  background-color: green; /* color elements as green */
  opacity: 0.4;
  pointer-events: none; /* no pointer events, allows clicking through onto the element */
}

.highlight:not(.djs-connection) .djs-visual > :nth-child(1) {
  fill: green !important; /* color elements as green */
}






